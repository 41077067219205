.details {
  overflow-x: auto;
  &Row {
    display: flex;
    gap: 20px;
    & > * {
      flex: 0 0 200px;
      text-align: left;
    };
  }
  &Reader {
    display: flex;
    gap: 20px;
    font-weight: 600;
    & > * {
      flex: 0 0 200px;
      min-width: 10px;
      text-align: left;
    };
  }
}
