.leftSitebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: sticky !important;
  top: 0;
  overflow-y: auto;
  z-index: 5;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    display: none;
    position: fixed !important;
    top: 64px;
    left: 0;
    width: 100% !important;
    max-width: none !important;
    height: calc(100% - 64px);
  }

  &Open {
    @media (max-width: 1024px) {
      display: flex;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .logoContainer {
    position: relative;
    display: flex;
    align-items: center;
    padding: 24px 5px 12px 5px;
    justify-content: space-between;
    border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
  }

  .logoContainer.collapsed {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 5px 24px 5px;
    justify-content: center;
    transform: rotate(180deg);
    border-inline-start: 1px solid rgba(5, 5, 5, 0.06);
  }

  .menu {
    flex-grow: 1;
    font-weight: 500;
  }

  .endWrapepr {
    margin-top: auto;
    padding: 10px 0;
    width: 100%;
  }
}

.languageSelect {
  display: flex;
  column-gap: 15px;
  padding-left: 38px;
  margin-top: 15px;
}

.menu.collapsed :global(.ant-menu-item),
.menu.collapsed :global(.ant-menu-submenu-title)
{
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.menu.collapsed :global(.ant-menu.ant-menu-sub.ant-menu-inline > .ant-menu-item) {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.menu.collapsed :global(.ant-menu.ant-menu-sub.ant-menu-inline) {
  background: #fff;
}

.logoLang {
  width: 24px;
  height: 16px;
}

.logo {
  display: flex;
  padding: 0px 48.674px 0px 2.876px;
  align-items: center;
  gap: 1.798px;
  flex: 1 0 0;

  svg {
    width: 100%;
    height: 43px;
  }
}

.logo.collapsed {
  display: none;
}

.menuWrapper {
  height: calc(100% - 92px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.endContainer {
  display: flex;
  flex-direction: column;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
}

.lang {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  gap: 12px;

  &.langCollapsed {
    display: flex;
    justify-content: center;
  }
}

.langBtn {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  margin-left: auto;

  &:hover {
    background: none !important;
    color: inherit !important;
    border: none !important;
  }
}

.logoLangLocations {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.logOutButton {
  width: 100%;
  display: flex;
  height: 38px;
  font-weight: 500;
  padding: 8px 16px;
  align-items: center;
  border: none;
  color: #E94444;
  transition: all 0.3s ease;

  &:hover {
    color: #E94444 !important;
    background: none;
  }

  &.collapsedButton {
    justify-content: center;
    padding: 0;
    width: auto;
  }
}

.lastNumber {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;

  &.collapsed {
    color: inherit;
    background: none;
    border: none;
    padding: 0;
    text-align: center;
    display: inline;

    &:hover {
      border: none !important;
      background-color: transparent !important;
      color: inherit !important;
      box-shadow: none !important;
    }
  }
}

.lastNumberButton {
  border-radius: 8px;

  &.collapsedButton {
    padding: 0;
    border: none !important;
    background-color: transparent !important;
    color: inherit !important;
    box-shadow: none !important;
  }
}

.userName {
  overflow: hidden;
  color: var(--Text-text-primary, #22262B);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  font-weight: 500;
  margin: 0;
  &.userNameCollapsed {
    display: none;
  }
}

:global(.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed) {
  min-width: 64px !important;
  max-width: 64px !important;
}

.mobileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  background: #fff;
  height: 64px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-weight: 500;
}

.menuToggleBtn {
  font-size: 18px;
  border: none;
  background: none;
  cursor: pointer;
}

.fullScreenMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.menuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #eaeaea;
  height: 64px;
}

.mobileLogo {
  width: 155px;
  height: 64px;
}

.mobileHeaderContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 64px;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  :global(.ant-menu-light.ant-menu-root.ant-menu-inline) {
    height: 100%;
  }

  .menu {
    background-color: #F4F4F4;
    overflow: scroll;
    color: var(--Text-text-primary, #22262B);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    padding: 24px;
  }

  .menu :global(.ant-menu-item),
  .menu :global(.ant-menu-submenu-title) {
    margin-bottom: 8px !important;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    background: var(--Background-bg-surface, #FFF);
  }

  .menu :global(.ant-menu-item) {
    border-bottom: 1px solid #e8e8e8;
    background-color: #FFFFFF;
    height: 46px !important;
  }

  .menu :global(.ant-menu-submenu-title) {
    border-bottom: 1px solid #e8e8e8;
    background-color: #FFFFFF;
    height: 46px !important;
  }

  .menu :global(.ant-menu.ant-menu-sub.ant-menu-inline) {
    background-color: #F4F4F4;
  }

  .menu .ant-menu-submenu-arrow {
    margin-left: auto;
  }

  .menu .ant-menu-submenu {
    padding: 0 !important;
    margin: 0 !important;
  }

  .logOutMobileMenuItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #E94444;
    gap: 10px;
    padding: 0;
  }

  .lang {
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 0;
    gap: 10px;

    &.langCollapsed {
      display: flex;
      justify-content: center;
    }
  }

  .langBtn {
    border-radius: 50%;
    width: 16px;
    height: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    margin-left: auto;

    &:hover {
      background: none !important;
      color: inherit !important;
      border: none !important;
    }
  }

  .navWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }

  .menuToggleBtn {
    border: none !important;
    background: none !important;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
      color: #000;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      background: none;
    }
  }
}
