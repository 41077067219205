.workCard {
  margin-bottom: 24px;
}

.workWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 16px;
}
