@import "/src/style/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table {
  margin-top: 16px;
}

.status {
  display: inline-block;
  margin: 0 auto;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  border: 1px solid;
  font-size: 10px;
  line-height: 16px;

  &__open {
    background: #FFF7E6;
    border-color: #FFD591;
    color: #FA8C16;
  }

  &__close {
    background: #F6FFED;
    border-color: #B7EB8F;
    color: #52C41A;
  }
}

.upload {
  margin: 10px 0;
}


.statistics {
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  background: #09203F;
  color: #ffffff;
  column-gap: 5px;
}

.topActions {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.card {
  margin-bottom: 24px;
  border-radius: 16px;
  :global(.ant-card-body){
    padding-bottom: 0;
  }
}

.plusButton {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  color: var(--Text-text-primary, #22262B);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  margin: 0 0 8px 0;
}

.titleCard {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #22262B;
  margin: 0 0 16px 0;
}

.leftText{
  overflow: hidden;
  color: var(--Text-text-tertiary, #919395);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  margin: 0;
}

.rightText {
  overflow: hidden;
  color: var(--Text-text-primary, #22262B);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  margin: 0;
}

.cardAgreementStyle {
  background: #F7F8F9;
  border: none;
  :global(.ant-tabs-tab) {
    border-radius: 12px;
    background-color: #ffffff;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.3s;
    margin: 0 !important;
    font-size: 14px;
    line-height: 22px;
    color: #64676B;
  }

  :global(.ant-tabs-nav-list) {
    padding: 4px;
    gap: 2px;
    border-radius: 16px;
    background-color: #ffffff;
    border: 1px solid #F4F4F4;
  }

  :global(.ant-tabs-tab-active) {
    background-color: #F4F4F4;
  }

  :global(.ant-tabs-tab-btn) {
    font-size: 14px;
    line-height: 22px;
    color: #64676B;
  }

  :global(.ant-card-body) {
    padding: 24px 0 0 0;
  }
  :global(.ant-card-head) {
    padding: 0;
  }

  :global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: #22262B;
    font-size: 14px;
    line-height: 22px;
  }

  :global(.ant-tabs-tab:hover) {
    background-color: #F4F4F4;
  }

  :global(.ant-tabs-ink-bar) {
    display: none !important;
  }

  :global(.ant-tabs-top > .ant-tabs-nav::before) {
    border: none !important;
  }
}

