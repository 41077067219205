.card {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--strokes-dividers-stroke-50, #F4F4F4);
  background: var(--Background-bg-surface, #FFF);
}

.text {
  overflow: hidden;
  color: var(--Text-text-tertiary, #919395);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
