.cardStyle {
  background: #F7F8F9;
  border: none;
  :global(.ant-tabs-tab) {
    border-radius: 12px;
    background-color: #ffffff;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.3s;
    margin: 0 !important;
    font-size: 14px;
    line-height: 22px;
    color: #64676B;
  }

  :global(.ant-tabs-nav-list) {
    padding: 4px;
    gap: 2px;
    border-radius: 16px;
    background-color: #ffffff;
    border: 1px solid #F4F4F4;
  }

  :global(.ant-tabs-tab-active) {
    background-color: #F4F4F4;
  }

  :global(.ant-tabs-tab-btn) {
    font-size: 14px;
    line-height: 22px;
    color: #64676B;
  }

  :global(.ant-card-body) {
    padding: 24px 0 0 0;
  }
  :global(.ant-card-head) {
    padding: 0;
  }

  :global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: #22262B;
    font-size: 14px;
    line-height: 22px;
  }

  :global(.ant-tabs-tab:hover) {
    background-color: #F4F4F4;
  }

  :global(.ant-tabs-ink-bar) {
    display: none !important;
  }

  :global(.ant-tabs-top > .ant-tabs-nav::before) {
    border: none !important;
  }
}

.documentCard {
  padding: 0 24px 24px 24px;
  border-radius: 16px;
}

.documentBoldText {
  color: #454545;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 140% */
}
