
.tasks {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
}

.task {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;

  border-radius: 16px;
  background: #F8F8F8;

  &Top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    padding-bottom: 16px;

    border-bottom: 1px solid #EAEAEA;
  }
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.clock {
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    color:rgba(0, 0, 0, 0.50);
    margin: 0;
  }
}

.rows {
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin: 16px 0 16px;
}

.row {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  align-items: center;

  margin: 0;

  span {
    color: rgba(0, 0, 0, 0.50);

    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }

  p {
    color: rgba(0, 0, 0, 0.88);

    font-size: 12px;
    font-weight: 400;
    line-height: 22px;

    margin: 0;
  }
}

.bottom {
 display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  width: 100%;

  padding-top: 16px;

  border-top: 1px solid #EAEAEA;

  cursor: pointer;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 24px;

    border-radius: 14px;

    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  &Black {
    background: #000;
    color: #fff;
  }
  &Grey {
    background: #EAEAEA;
    color: rgba(0, 0, 0, 0.88);

    gap: 8px;
  }
}
