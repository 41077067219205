@import "/src/style/variables";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  display: inline-block;
  margin: 0 auto;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  border: 1px solid;
  font-size: 10px;
  line-height: 16px;
}

.table {
  :global(.ant-table-row) {
    height: 64px;
  }
  :global(.ant-table-cell) {
    line-height: 64px;
  }
}
