.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table {
  margin-top: 16px;
}

.actions {
  display: flex;
  align-items: center;
  margin: 0 -10px;
  justify-content: center;

  &_item {
    margin: 0 10px;
    display: flex;
    align-items: center;

    svg {
      width: 15px;
      height: 15px;
      transition-duration: .3s;
    }

    &__isNotRead {
      color: #022e04;

      &:hover {
        color: darken(rgb(6, 14, 1), 10%);
      }
    }

    &__isRead {
      color: #1ab81f;
    }
  }
}

.sentCustomer {
  cursor: pointer;
}
