.customPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 40px;
}

:global(.ant-pagination .ant-pagination-item) {
  background-color: #F7F8F9 !important;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #595959;
  cursor: pointer;
  margin: 0;
}

:global(.ant-pagination .ant-pagination-item-active) {
  background-color: #F7F8F9 !important;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

:global(.ant-pagination .ant-pagination-item:hover) {
  background-color: #F7F8F9 !important;
  color: #64676B;
}

:global(.ant-pagination .ant-pagination-item a) {
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 22px;
  color: #64676B;
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(.ant-pagination .ant-pagination-item-active a) {
  font-size: 14px;
  line-height: 22px;
  color: #22262B !important;
}

:global(.ant-pagination .ant-pagination-item-active:hover) {
  background-color: #F7F8F9 !important;
  color: #22262B !important;
}

:global(.ant-pagination-prev),
:global(.ant-pagination-next) {
  position: absolute;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #22262B;
  cursor: pointer;
}

:global(.ant-pagination-prev) {
  left: 24px;
}

:global(.ant-pagination-next) {
  right: 4px;
}

:global(.ant-pagination .ant-pagination-disabled) {
  color: #d9d9d9;
  cursor: not-allowed;
  background-color: transparent !important;
}

:global(.ant-pagination .ant-pagination-jump-next),
:global(.ant-pagination .ant-pagination-jump-prev) {
  margin: 0;
}
