.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.wrapper {
  display: flex;
}

.buttonText {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--Text-text-secondary, #64676B);
}

:global(.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select) {
  width: 100%;
  gap: 8px;
  border: none;
  height: 38px;
  padding: 0;
  display: inline-flex;
  background: none;
  pointer-events: none;
}

:global(.ant-upload) {
  border-radius: 8px;
  padding: 8px 16px;
  background: var(--button-bg-secondary-default-hover, #F4F4F4);
  pointer-events: auto;
  cursor: pointer;
  @media (max-width: 1024px) {
    width: 100%;
    margin-inline-end: 0 !important;
  }
}

:global(.ant-upload-list.ant-upload-list-picture-card) {
  display: flex;
  flex-wrap: wrap;
}

:global(.ant-upload.ant-upload-select) {
  width: 100%;
  order: -1;
}

:global(.ant-upload-list-item-container) {
  display: flex;
  gap: 8px;
}

:global(.ant-upload-list-item-container:last-child) {
  order: -1;
}

:global(.ant-upload-list-item-container img) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

:global(
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item
) {
  border: none;
}

:global(
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail
) {
  width: 81px;
  height: 81px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}

:global(
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail img
) {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: filter 0.3s ease;
}

:global(
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container
) {
  width: 81px;
  height: 81px;
}

:global(
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item::before
) {
  width: 81px;
  height: 81px;
  border-radius: 16px;
}

:global(
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item
) {
  padding: 0;
}

:global(
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container
) {
  margin-inline: 0 4px;
}
