.wrapper {
  height: 100%;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 !important;
}

.button {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  border-radius: 8px;
}

.buttonContainer {
  display: flex;
  margin-top: auto;
}

.workerTitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #22262B;
  margin: 0 0 16px 0;
}
