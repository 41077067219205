.form {
  margin-top: 45px;
}

.link {
  margin-left: 20px;
  color: #000000;
  background: #ededed;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 300;
}

.suma {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #919395;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 8px;

  .value {
    color: #22262B;
  }

  &Total {
    color: #22262B;
    font-size: 16px;
    font-weight: 600;
  }
}

.card {
  margin-bottom: 24px;
  border-radius: 16px;
  :global(.ant-card-body){
    padding-bottom: 0;
  }
}
