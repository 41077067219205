.comments {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow-y: auto;
  padding-bottom: 24px;
  height: 65vh;
}

.comment {
  position: relative;
  width: 90%;
  border-radius: 12px;
  padding: 12px;
  background: #F8F8F9;

  &Me {
    margin-left: auto;
    background-color: #FAF5EB;
  }
}

.textWrapper {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 0 !important;
}

.form:not(.not-card) {
  padding: 0 8px;
}

.name {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #22262B;
}

.images {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.timestamp {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #919395;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;

}

.button {
  height: 38px;
  background: #22262B;
  border-radius: 8px;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 8px;
  width: 100%;
  &:disabled {
    background: #F4F4F4;
    color: #D3D4D5;
    cursor: not-allowed;
    border: none;
  }
}

.buttonDisabled {
  height: 38px;
  background: #F4F4F4;
  color: #64676B;
  border-radius: 8px;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 8px;
  width: 100%;
}

.text {
  color: #64676B;
  font-size: 14px;
  line-height: 22px;
}
