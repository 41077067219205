.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #22262B;
  margin: 0;
  margin-bottom: 8px;
}

.cardText {
  font-size: 14px;
  line-height: 22px;
  color: #919395;
}

.cardTextResult {
  font-size: 14px;
  line-height: 22px;
  color: #22262B;
}

.categoriesContainer {
  display: flex;
  flex-direction: column;
}
