.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table {
  margin-top: 16px;

  &Footer {
    display: flex;
    justify-content: center;
  }
}

.sentCustomer {
  cursor: pointer;
}
