.wrapper {
  margin-bottom: 24px;
  position: relative;
  width: 100%;

  &NoMargin {
    margin-bottom: 0;
  }

  &NoLable {
    margin-bottom: 8px;
  }
}

.error {
  position: absolute;
  top: 100%;
  left: 0;
  color: red;
  font-size: 12px;

  &Border {
    border-color: red !important;

    :global(.ant-select-selector) {
      border-color: red !important;
    }
  }
}

.labelText {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #919395;
  height: 16px;
}

.placeholder,
.label {
  gap: 4px;
  display: flex;
  flex-direction: column;
}

.suffixContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.spinner {
  margin-right: 8px;
}

.tagStyle {
  margin-right: 8px;
  margin-left: 8px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 16px;
}

:global(.ant-select-dropdown .ant-select-item) {
  color: #64676B;
  background-color: #FFFFFF;
  padding: 8px 16px;
}

:global(.ant-select-dropdown .ant-select-item:hover) {
  background-color: #FFFFFF;
  color: #22262B;
}

:global(.ant-select-dropdown .ant-select-item-selected) {
  color: #22262B;
  background-color: #F4F4F4;
}

:global(.ant-select-selector .ant-select-selection-item-remove) {
  margin-left: 16px;
  width: 16px;
}

:global(.ant-color-picker-trigger) {
  height: 38px;
  padding: 4px 8px 4px 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
}

:global(.ant-color-picker-trigger.ant-color-picker-lg .ant-color-picker-color-block) {
  width: 100%;
  height: 22px;
  border-radius: 4px;
}

:global(.ant-color-picker-trigger.ant-color-picker-lg .ant-color-picker-trigger-text) {
  line-height: 10px;
  margin: 0 auto;
}


