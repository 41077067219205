.imgContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  cursor: pointer;
}

.imgContainer img {
  width: 100px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: filter 0.3s ease;
}

.imgContainer img:hover {
  filter: grayscale(100%) brightness(0.6);
}

.imageWrapper {
  position: relative;
  display: inline-block;
}

.imageWrapper:hover img {
  filter: grayscale(100%) brightness(0.6);
}

.imageWrapper:hover .actions {
  opacity: 1;
}

.actions {
  opacity: 0;
  display: flex;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: opacity 200ms ease-in-out;
}

.icon {
  transform: scale(1.2);
  color: rgba(255, 255, 255, 0.65);
}

.icon:hover {
  color: #ffffff;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

/* Collapse Styles */
:global(.ant-collapse) {
  border: none !important;
}

:global(.ant-collapse-item) {
  border: none !important;
}

:global(.ant-collapse-header-text) {
  color: #22262B;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}

:global(.ant-collapse-header) {
  background-color: #FFFFFF;
  padding: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

:global(.ant-collapse>.ant-collapse-item:first-child>.ant-collapse-header) {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

:global(.ant-collapse>.ant-collapse-item>.ant-collapse-header) {
  padding: 24px;
}

:global(.ant-collapse-header[aria-expanded="true"]) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

:global(.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header[aria-expanded="true"]) {
  border-radius: 0 !important;
}

:global(.ant-collapse-content.ant-collapse-content-active) {
  padding: 0;
}

:global(.ant-collapse-content-box) {
  padding: 0 24px 0 24px !important;
}

:global(.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-content.ant-collapse-content-active) {
  padding-bottom: 24px !important;
}

:global(.ant-collapse>.ant-collapse-item>.ant-collapse-header[aria-expanded="true"]) {
  padding-bottom: 16px;
}

:global(.ant-collapse-content) {
  border-top: none !important;
}

:global(.ant-collapse .ant-motion-collapse) {
  transition: none !important;
}


:global(.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header) {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

:global(.ant-collapse .ant-collapse-item:last-child>.ant-collapse-content) {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

:global(.ant-collapse-content-active) {
  border: none !important;
}

:global(.ant-collapse.ant-collapse-icon-position-end) {
  border-radius: 16px !important;
  border: 1px solid #F4F4F4 !important;
}

.customCollapseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

:global(.ant-collapse>.ant-collapse-item:only-child>.ant-collapse-header) {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

:global(.ant-collapse>.ant-collapse-item:only-child>.ant-collapse-content) {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

:global(.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header[aria-expanded="true"]) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
