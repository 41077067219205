.buttonEdit {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  border-radius: 8px;
}

.carCardStyle {
  background: #F7F8F9;
  border: none;
  :global(.ant-tabs-tab) {
    border-radius: 12px;
    background-color: #ffffff;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.3s;
    margin: 0 !important;
    font-size: 14px;
    line-height: 22px;
    color: #64676B;
  }

  :global(.ant-tabs-nav-list) {
    padding: 4px;
    gap: 2px;
    border-radius: 16px;
    background-color: #ffffff;
    border: 1px solid #F4F4F4;
  }

  :global(.ant-tabs-tab-active) {
    background-color: #F4F4F4;
  }

  :global(.ant-tabs-tab-btn) {
    font-size: 14px;
    line-height: 22px;
    color: #64676B;
  }

  :global(.ant-card-body) {
    padding: 24px 0 0 0;
  }
  &.driver {
    :global(.ant-card-body) {
      padding: 0;
    }
  }
  :global(.ant-card-head) {
    padding: 0;
  }

  :global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: #22262B;
    font-size: 14px;
    line-height: 22px;
  }

  :global(.ant-tabs-tab:hover) {
    background-color: #F4F4F4;
  }

  :global(.ant-tabs-ink-bar) {
    display: none !important;
  }

  :global(.ant-tabs-top > .ant-tabs-nav::before) {
    border: none !important;
  }
}

.button {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  border-radius: 8px;
}

:global(.ant-tabs-extra-content) {
  display: flex;
  height: 48px;
  align-items: center;
}

.customDescriptions {
  display: flex;
  flex-direction: column;
  :global(.ant-descriptions-item) {
    padding-bottom: 8px !important;
  }
  .ant-descriptions-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  .cardText {
    font-size: 14px;
    line-height: 22px;
    color: #919395;
  }

  .cardTextResult {
    font-size: 14px;
    line-height: 22px;
    color: #22262B;
  }
}

.info {
  color: #22262B;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin: 0 0 8px 8px;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
