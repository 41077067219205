.form {
  margin-top: 45px;
}

.btn {
  margin-bottom: 24px;
}

.link {
  margin-left: 20px;
  color: #000000;
  background: #ededed;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 300;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #22262B;
  margin: 0;
  margin-bottom: 8px;
}

.card {
  border-radius: 16px;
  margin-bottom: 24px;
  :global(.ant-card-body) {
    padding: 24px 24px 0 24px;
  }
  &_noMargin {
    border-radius: 16px;
    :global(.ant-card-body) {
      padding: 24px 24px 16px 24px;
    }
  }
  &_margin {
    border-radius: 16px;
    :global(.ant-card-body) {
      padding: 24px;
    }
  }
}

.button {
  display: flex;
  height: 38px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  background: var(--button-bg-secondary-default-hover, #F4F4F4);
  overflow: hidden;
  color: var(--Text-text-secondary, #64676B);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  margin-bottom: 24px;
  &:hover {
    background: var(--button-bg-secondary-default-hover, #F4F4F4) !important;
    color: var(--Text-text-secondary, #64676B) !important;
  }
}

.distance {
  color: var(--Text-text-tertiary, #919395);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0 8px 0 0;
}

.distanceText {
  color: var(--Text-text-primary, #22262B);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
   margin: 0;
}

.distanceWrapper {
  margin: 0 0 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cardTitle {
  margin: 0;
  overflow: hidden;
  color: var(--Text-text-tertiary, #919395);
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.cardText {
  margin: 0;
  overflow: hidden;
  color: var(--Text-text-primary, #22262B);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  white-space: nowrap;
}

.cardLink {
  margin: 0;
  overflow: hidden;
  color: var(--blue, #3979F5);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  white-space: nowrap;
  cursor: pointer;
}

.carCardStyle {
  border: none;
  background-color: #F7F8F9;
  :global(.ant-tabs-tab) {
    border-radius: 12px;
    background-color: #ffffff;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.3s;
    margin: 0 !important;
    font-size: 14px;
    line-height: 22px;
    color: #64676B;
  }

  :global(.ant-tabs-nav-list) {
    padding: 4px;
    gap: 2px;
    border-radius: 16px;
    background-color: #ffffff;
    border: 1px solid #F4F4F4;
  }

  :global(.ant-tabs-tab-active) {
    background-color: #F4F4F4;
  }

  :global(.ant-tabs-tab-btn) {
    font-size: 14px;
    line-height: 22px;
    color: #64676B;
  }

  :global(.ant-card-body) {
    padding: 12px 0 0 0;
  }
  :global(.ant-card-head) {
    padding: 0;
  }

  :global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: #22262B;
    font-size: 14px;
    line-height: 22px;
  }

  :global(.ant-tabs-tab:hover) {
    background-color: #F4F4F4;
  }

  :global(.ant-tabs-ink-bar) {
    display: none !important;
  }

  :global(.ant-tabs-top > .ant-tabs-nav::before) {
    border: none !important;
  }
}
