.form {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.plusButton {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
