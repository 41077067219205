@import "/src/style/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table {
  margin-top: 16px;
}
