.carCardStyle {
  border: none;
  :global(.ant-tabs-tab) {
    border-radius: 12px;
    background-color: #ffffff;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.3s;
    margin: 0 !important;
    font-size: 14px;
    line-height: 22px;
    color: #64676B;
  }

  :global(.ant-tabs-nav-list) {
    padding: 4px;
    gap: 2px;
    border-radius: 16px;
    background-color: #ffffff;
    border: 1px solid #F4F4F4;
  }

  :global(.ant-tabs-tab-active) {
    background-color: #F4F4F4;
  }

  :global(.ant-tabs-tab-btn) {
    font-size: 14px;
    line-height: 22px;
    color: #64676B;
  }

  :global(.ant-card-body) {
    padding: 24px 0 0 0;
  }
  :global(.ant-card-head) {
    padding: 0;
  }

  :global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: #22262B;
    font-size: 14px;
    line-height: 22px;
  }

  :global(.ant-tabs-tab:hover) {
    background-color: #F4F4F4;
  }

  :global(.ant-tabs-ink-bar) {
    display: none !important;
  }

  :global(.ant-tabs-top > .ant-tabs-nav::before) {
    border: none !important;
  }
}

.customTooltip {
  border-radius: 12px !important;
  // background: var(--Background-bg-contrast, #22262B) !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16) !important;

  :global(.ant-tooltip-inner) {
    background: var(--Background-bg-contrast, #22262B) !important;
    border-radius: 12px !important;
    color: #ffffff;
    min-width: 400px; // Минимальная ширина тултипа
    width: fit-content !important;
    padding: 0 12px;
  }

  :global(.ant-tooltip-content) {
    margin-right: 20px;
  }

  .tooltipContent {
    // background: #25282c;
    color: #ffffff;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    > *:not(:first-child) {
      border-top: 1px dashed #32373C;
    }
    .tooltipItem {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 8px;
      padding: 12px 0;
      .routeNumber {
        display: flex;
        width: 16px;
        gap: 10;
        height: 16px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid var(--Text-text-tertiary, #919395);
        overflow: hidden;
        color: var(--Text-text-tertiary, #919395);
        text-align: center;
        text-overflow: ellipsis;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }

      .routeDetails {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .label {
          margin-bottom: 2px;
          overflow: hidden;
          color: var(--Text-text-secondary, #64676B);
          text-overflow: ellipsis;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          white-space: nowrap;
        }

        .address {
          overflow: hidden;
          color: var(--Text-text-white, #FFF);
          text-overflow: ellipsis;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .distance {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        overflow: hidden;
        color: var(--Text-text-tertiary, #919395);
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
      }
    }

    .divider {
      border: none;
    }
  }
}
