.leftText{
  overflow: hidden;
  color: var(--Text-text-tertiary, #919395);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  margin: 0;
}

.rightText {
  overflow: hidden;
  color: var(--Text-text-primary, #22262B);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  margin: 0;
}

.card {
  border-radius: 16px;
}
