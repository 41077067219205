@use './variables';

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
}

button {
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: none;
  box-shadow: none!important;
}

h1 {
  font-weight: 500;
}

form {
  &.not-card {
    display: flex;
    flex-direction: column;
  }
}

.hide-for-print {
  @media print {
    display: none !important;
  }
}

.ant-layout-content {
  min-height: calc(100vh - 64px);
  @media print {
    background-color: #fff;
  }
}

.ant-card {
  @media print {
    border: none;
  }
}

.ant-card-body {
  @media print {
    padding: 0;
  }
}

.ant-col {
  @media print {
    max-width: none;
    flex: 1;
  }
}

///////
.agreement_document {
  line-height: 1.25;
  overflow-x: auto;

  .page-break {
    page-break-after: always;
  }

  .font-bold {
    font-size: 14px;
    font-weight: 700;
    margin: 8px 0;
  }

  .text {
    font-size: 12.5px;
    margin: 0;
    text-align: justify;
  }

  .table .row .col {
    padding: 4px 8px;
    width: 50%;
  }

  .table .row .col .text {
    font-size: 11px;
  }

  .table {
    border-left: 0.01em solid #000000;
    border-right: 0;
    border-top: 0.01em solid #000000;
    border-bottom: 0;
    border-collapse: collapse;
  }

  .table .col {
    border-left: 0;
    border-right: 0.01em solid #000000;
    border-top: 0;
    border-bottom: 0.01em solid #000000;
  }

  .table .text {
    margin: 0;
  }

  .signatures {
    width: 100%;
  }

  .signatures .text {
    margin: 0;
  }

  .signatures .row .col {
    padding: 8px 0px 0px;
    width: 50%;
  }

  .image-row {
    width: 50%;
    padding: 15px;
    border: 1px solid black;
    border-collapse: collapse;
  }

  .image-table {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .image-table tr {
    border: 1px solid black;
    border-collapse: collapse;
  }

  footer {
    position: fixed;
    bottom: -35px;
    left: 0px;
    right: 0px;
    height: 50px;
    color: #A9A9A9;
    text-align: center;
    line-height: 35px;
    font-size: 12px;
  }

  footer .page:after {
    content: counter(page, decimal);
  }

  footer .docnum {
    position: absolute;
    right: 0;
  }
}
.agreement_bills_document {
  line-height: 1.5;
  overflow-x: auto;

  .page-break {
    page-break-after: always;
  }

  .font-bold {
    font-size: 16px;
    font-weight: 700;
  }

  .text {
    font-size: 14px;
  }

  .table .row .col {
    padding: 8px;
    width: 50%;
  }

  .table {
    border-left: 0.01em solid #000000;
    border-right: 0;
    border-top: 0.01em solid #000000;
    border-bottom: 0;
    border-collapse: collapse;
  }

  .table .col {
    border-left: 0;
    border-right: 0.01em solid #000000;
    border-top: 0;
    border-bottom: 0.01em solid #000000;
  }

  .table .text {
    margin: 0;
  }

  .signatures td {
    width: 50%;
  }

  .image-row {
    width: 50%;
    padding: 15px;
    border: 1px solid black;
    border-collapse: collapse;
  }

  .image-table {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .image-table tr {
    border: 1px solid black;
    border-collapse: collapse;
  }
}
.agreement_invoices_bills_document {
  line-height: 1.5;
  overflow-x: auto;

  .font-bold {
    font-size: 16px;
    font-weight: 700;
  }

  .text {
    font-size: 14px;
  }

  .table .row .col {
    padding: 8px;
    width: 50%;
  }

  .table {
    border-left: 0.01em solid #000000;
    border-right: 0;
    border-top: 0.01em solid #000000;
    border-bottom: 0;
    border-collapse: collapse;
  }

  .table .col {
    border-left: 0;
    border-right: 0.01em solid #000000;
    border-top: 0;
    border-bottom: 0.01em solid #000000;
  }

  .table .text {
    margin: 0;
  }

  .signatures td {
    width: 50%;
  }

  .image-row {
    width: 50%;
    padding: 15px;
    border: 1px solid black;
    border-collapse: collapse;
  }

  .image-table {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .image-table tr {
    border: 1px solid black;
    border-collapse: collapse;
  }
}
.transportation_bills_document {
  line-height: 1.5;
  overflow-x: auto;

  .page-break {
    page-break-after: always;
  }

  .font-bold {
    font-size: 16px;
    font-weight: 700;
  }

  .text {
    font-size: 14px;
  }

  .table .row .col {
    padding: 8px;
    width: 50%;
  }

  .table {
    border-left: 0.01em solid #000000;
    border-right: 0;
    border-top: 0.01em solid #000000;
    border-bottom: 0;
    border-collapse: collapse;
  }

  .table .col {
    border-left: 0;
    border-right: 0.01em solid #000000;
    border-top: 0;
    border-bottom: 0.01em solid #000000;
  }

  .table .text {
    margin: 0;
  }

  .signatures td {
    width: 50%;
  }

  .image-row {
    width: 50%;
    padding: 15px;
    border: 1px solid black;
    border-collapse: collapse;
  }

  .image-table {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .image-table tr {
    border: 1px solid black;
    border-collapse: collapse;
  }
}
///////
.document {
  a {
    background-color: transparent
  }

  [hidden] {
    display: none
  }

  *, :after, :before {
    box-sizing: border-box;
    border: 0 solid #e2e8f0
  }

  a {
    color: inherit;
    text-decoration: inherit
  }

  .text-center {
    text-align: center
  }

  .font-bold {
    font-size: 16px;
    font-weight: 700;
  }

  .text {
    font-size: 14px;
  }

  .table .row .col {
    padding: 8px;
    width: 50%;
  }

  .table {
    border-left: 0.01em solid #000000;
    border-right: 0;
    border-top: 0.01em solid #000000;
    border-bottom: 0;
    border-collapse: collapse;
  }

  .table .col {
    border-left: 0;
    border-right: 0.01em solid #000000;
    border-top: 0;
    border-bottom: 0.01em solid #000000;
  }

  .table .text {
    margin: 0;
  }

  .signatures td {
    width: 50%;
  }

  .image-row {
    width: 50%;
    padding: 15px;
    border: 1px solid black;
    border-collapse: collapse;
  }

  .image-table {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .image-table tr {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .table {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .table tr {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .table thead td {
    font-weight: bold;
  }

  .table td {
    width: 50%;
    line-height: 1;
    padding: 8px 16px;
    border: 1px solid black;
    border-collapse: collapse;
  }
}
.invoices_document {
  line-height: 1.5;

  a {
    background-color: transparent
  }

  [hidden] {
    display: none
  }

  *, :after, :before {
    box-sizing: border-box;
    border: 0 solid #e2e8f0
  }

  a {
    color: inherit;
    text-decoration: inherit
  }

  .text-center {
    text-align: center
  }

  .font-bold {
    font-size: 16px;
    font-weight: 700;
  }

  .text {
    font-size: 14px;
  }

  .table .row .col {
    padding: 8px;
    width: 50%;
  }

  .table {
    border-left: 0.01em solid #000000;
    border-right: 0;
    border-top: 0.01em solid #000000;
    border-bottom: 0;
    border-collapse: collapse;
  }

  .table .col {
    border-left: 0;
    border-right: 0.01em solid #000000;
    border-top: 0;
    border-bottom: 0.01em solid #000000;
  }

  .table .text {
    margin: 0;
  }

  .signatures td {
    width: 50%;
  }

  .image-row {
    width: 50%;
    padding: 15px;
    border: 1px solid black;
    border-collapse: collapse;
  }

  .image-table {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .image-table tr {
    border: 1px solid black;
    border-collapse: collapse;
  }
}

.documentActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  align-items: center;
}

@media (max-width: 1024px) {
  .ant-drawer-content-wrapper {
    width: 100%!important;
  }
}

.table_actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  &_item {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 16px;
      height: 16px;
      transition-duration: .3s;
    }

    &__pencil {
      color: #ABABAB;

      &:hover {
        color: variables.$primary;
      }
    }

    &__delete {
      color: #E94444;
      cursor: pointer;
      &:hover {
        color: darken(#E94444, 10%);
      }
    }
  }
}

.disabled-link {
  pointer-events: none;
  cursor: not-allowed;
}

.hidden {
  display: none;
}

.card-button {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  border-radius: 8px;
  width: fit-content !important;
  margin-top: auto;
}

.status {
  display: inline-block;
  margin: 0 auto;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  border: 1px solid;
  font-size: 10px;
  line-height: 16px;
}
