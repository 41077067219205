:global(.ant-card-head-wrapper) {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

:global(.ant-card .ant-card-head) {
  border-bottom: none;
  min-height: 38px;
}

.cardWrapper {
  :global(.ant-card-body) {
    padding: 24px 0 0 0;
  }
  :global(.ant-card-head) {
    padding: 0;
  }
}

.button {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  border-radius: 8px;
}

:global(.ant-drawer .ant-drawer-content) {
  border-radius: 16px 0 0 16px;
  @media (max-width: 1024px) {
    border-radius: 0;
  }
}

:global(.ant-drawer .ant-drawer-header) {
  border-bottom: none;
  padding: 24px 24px 0 24px;
}

:global(.ant-drawer .ant-drawer-header-title) {
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

:global(.ant-drawer .ant-drawer-title) {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #22262B;
}

:global(.ant-drawer .ant-drawer-close) {
  color: #22262B;
  font-size: 16px;
}

:global(.ant-drawer-right > .ant-drawer-content-wrapper) {
  box-shadow: none;
}

:global(.ant-table-wrapper .ant-table-selection-column) {
  width: 48px !important;
  height: 48px !important;
  padding-inline-end: 0;
  padding-inline-start: 0;
}

.cardActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 8px;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

:global(.ant-table-wrapper) {
  border-radius: 16px;
  overflow: hidden;
}

:global(.ant-table-wrapper .ant-table) {
  border-radius: 16px;
  border: 1px solid #F4F4F4;
}

:global(.ant-table-wrapper .ant-table-thead > tr > th) {
  font-size: 12px;
  line-height: 16px;
  padding: 0 16px;
  font-weight: 600;
  border-right: 1px solid #E8E8E8;
}

:global(.ant-table-wrapper .ant-table tr) {
  height: 48px;
}

:global(.ant-table-wrapper .ant-table tbody > tr:last-child > td) {
  border-bottom: none;
}

:global(.ant-table-tbody .ant-table-cell) {
  padding: 16px;
  font-size: 12px;
  line-height: 16px;
}

:global(.ant-table-cell) {
  border-right: 1px solid #E8E8E8;
  color: #64676B;
}

:global(.ant-table-cell:last-child) {
  border-right: none;
}

:global(.ant-table-tbody > tr:last-child .ant-table-cell) {
  border-bottom: none;
}

:global(.ant-table .ant-table-tbody > tr > td) {
  padding: 0 16px;
}
