.button {
  border: none;
  padding: 0;
  margin: 0;
  transition-duration: 0.3s;
  position: relative;
  &.fullContent {
    width: 100%;
  }
}

.wrapper {
  display: flex;
  align-items: center;

  &Spin {
    opacity: 0;
  }
}

.primary {
  background: var(--Button-bg-primary-default, #22262B);
  color: var(--Text-text-white, #FFF);

  &:hover {
    background: var(--Button-bg-primary-hover, #2D3136);
  }

  &:focus {
    background: var(--Button-bg-primary-default, #22262B);
  }

  &:active:not(.loading) {
    background: var(--Button-bg-primary-pressed, #383C40);
  }

  &:disabled:not(.loading) {
    background: var(--Button-bg-disabled, #F4F4F4);
    color: var(--Text-text-quaternary, #D3D4D5);
  }

  &.active {
    background: var(--Button-bg-primary-pressed, #383C40);
  }
}

.secondary {
  background: var(--button-bg-secondary-default-hover, #F4F4F4);
  color: var(--Text-text-secondary, #64676B);


  &:hover {
    background: var(--button-bg-secondary-default-hover, #F4F4F4);
    color: var(--Text-text-primary, #22262B);
  }

  &:focus {
    background: var(--Button-bg-secondary-pressed, #E9E9EA);
    color: var(--Text-text-primary, #22262B);
  }

  &:active:not(.loading) {
    background: var(--Button-bg-secondary-pressed, #E9E9EA);
    color: var(--Text-text-primary, #22262B);
  }

  &:disabled:not(.loading) {
    background: var(--Button-bg-disabled, #F4F4F4);
    color: var(--Text-text-quaternary, #D3D4D5);
  }
}

.large {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  border-radius: 8px;

  .wrapper {
    gap: 8px;
  }
  &.notChildren {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.medium {
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  border-radius: 8px;

  .wrapper {
    gap: 4px;
  }
}

.small {
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 8px;

  .wrapper {
    gap: 4px;
  }
}

.spin {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
}
