.form {
  margin-top: 45px;
}

.upload {
  margin-bottom: 10px;
  margin-top: 24px;
}


.table {
  margin-top: 16px;
}

.card {
  margin-bottom: 24px;
  border-radius: 16px;
  :global(.ant-card-body){
    padding-bottom: 0;
  }
}

.plusButton {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  color: var(--Text-text-primary, #22262B);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  margin: 0 0 8px 0;
}

.cardTitle {
  overflow: hidden;
  color: var(--Text-text-primary, #22262B);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin: 0 0 16px 0;
}

