.card {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin-bottom: 24px;
  height: calc(100% - 34px);
  :global(.ant-card-body) {
    padding: 24px;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.container {
  height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
}

.cardContent {
  flex: 1;
}

.cardFooter {
  display: flex;
  margin-top: auto;
}

.styleButton {
  display: flex;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 100% !important;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #22262B;
  margin: 0;
  margin-bottom: 8px;
}


