.deleteModal {
  width: 416px;
}

.deleteModal :global(.ant-modal-content) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--strokes-dividers-stroke-50, #F4F4F4);
}

.deleteModal :global(.ant-modal-header) {
  border: none;
  margin-bottom: 12px;
}

.deleteModal :global(.ant-modal-title) {
  color: var(--Text-text-primary, #22262B);
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.closeIcon {
  margin-left: auto;
  cursor: pointer;
  width: 24px;
  height: 24px;
  fill: var(--System-red-500, #E94444);
  transition: fill 0.3s;

  &:hover {
    fill: var(--System-red-700, #C73232);
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.deleteModal :global(.ant-modal-confirm-paragraph) {
  max-width: 100%;
  row-gap: 12px;
}

.deleteModal :global(.ant-modal-close) {
  top: 25px;
}

.deleteModal :global(.ant-modal-confirm-btns) {
  width: 100%;
  display: flex;
  margin-top: 24px;
}

.modalText {
  color: var(--Text-text-secondary, #64676B);
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.modalButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  flex: 1 0 0;
  border-radius: 8px;
  height: 38px;
  background: var(--System-red-500, #E94444);
  overflow: hidden;
  color: var(--Text-text-white, #FFF) !important;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  &:hover {
    background: var(--System-red-500, #E94444) !important;
    color: var(--Text-text-white, #FFF) !important;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    border: 1px solid #E94444;
  }
}

.modalButtonCancel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  flex: 1 0 0;
  border-radius: 8px;
  border: none;
  height: 38px;
  background: var(--button-bg-secondary-default-hover, #F4F4F4);
  overflow: hidden;
  color: var(--Text-text-secondary, #64676B);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  &:hover {
    background: var(--button-bg-secondary-default-hover, #F4F4F4) !important;
    color: var(--Text-text-secondary, #64676B) !important;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
  }
}
