.wrapper {
  display: flex;
  gap: 16px;
}

.placeholder {
  font-size: 12px;
  line-height: 16px;
  color: #64676B;
  display: flex;
  align-items: center;
}
