.actions {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.button {
  display: flex;
  align-items: center;

  padding: 10px 24px;
  width: fit-content;

  border-radius: 14px;
  background: #000;

  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px
}

.form:not(.not-card) {
  padding: 0;
}

.back {
  margin-bottom: 28px;
}
