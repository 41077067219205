@import "/src/style/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table {
  margin-top: 16px;
}

.status {
  display: inline-block;
  margin: 0 auto;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  border: 1px solid;
  font-size: 10px;
  line-height: 16px;

  &__rent {
    background: #FFF7E6;
    border-color: #FFD591;
    color: #FA8C16;
  }

  &__free {
    background: #F6FFED;
    border-color: #B7EB8F;
    color: #52C41A;
  }

  &__repair {
    background: #FFDEDE;
    border-color: #FF0000;
    color: #FF0000;
  }
}
