.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
  margin-top: auto;
}

.cardText {
  font-size: 14px;
  line-height: 22px;
  color: #919395;
}

.cardTextResult {
  font-size: 14px;
  line-height: 22px;
  color: #22262B;
}

a.cardTextResult {
  text-decoration: underline;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #22262B;
  margin: 0;
  margin-bottom: 8px;
}

.categoriesContainer {
  display: flex;
  flex-direction: column;
}

.button {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  border-radius: 8px;
  width: fit-content;
}
