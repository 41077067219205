.form {
  margin-top: 45px;
}

.btn {
  margin-bottom: 24px;
}

.link {
  margin-left: 20px;
  color: #000000;
  background: #ededed;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 300;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #22262B;
  margin: 0;
  margin-bottom: 8px;
}

.card {
  border-radius: 16px;
  margin-bottom: 24px;
  :global(.ant-card-body) {
    padding: 24px 24px 0 24px;
  }
  &_noMargin {
    border-radius: 16px;
    :global(.ant-card-body) {
      padding: 24px 24px 16px 24px;
    }
  }
  &_margin {
    border-radius: 16px;
    margin-bottom: 24px;
    :global(.ant-card-body) {
      padding: 24px;
    }
  }
  &_carringMarg {
    border-radius: 16px;
    margin-bottom: 24px;
    :global(.ant-card-body) {
      padding: 24px 24px 0 24px;
    }
  }
}

.button {
  display: flex;
  height: 38px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  background: var(--button-bg-secondary-default-hover, #F4F4F4);
  overflow: hidden;
  color: var(--Text-text-secondary, #64676B);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  margin-bottom: 24px;
  &:hover {
    background: var(--button-bg-secondary-default-hover, #F4F4F4) !important;
    color: var(--Text-text-secondary, #64676B) !important;
  }
}

.distance {
  color: var(--Text-text-tertiary, #919395);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0 8px 0 0;
}

.distanceText {
  color: var(--Text-text-primary, #22262B);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
   margin: 0;
}

.distanceWrapper {
  margin: 0 0 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.styleButton {
  display: flex;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 100% !important;
  }
}
