.quiz {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 8px;

  &Question {
    border-bottom: 1px solid #d9d9d9;
    font-size: 14px;
    padding-bottom: 8px;
  }

  &Answer {
    font-size: 12px;
    margin-top: 8px;
  }
}

.details {
  overflow-x: auto;

  &Row {
    display: flex;
    gap: 20px;

    & > * {
      flex-shrink: 0;
    }
  }
}
