.container {
  margin: 0 auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("/assets/login-bg.png"), linear-gradient(#09203F,#537895);
  background-size: cover;
  background-position: center;
  width: 100%;
  position: relative;

  h1 {
    margin: 0 0 24px;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
  }
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: 0;
  background: rgba(9,32,63,.6);
  height: 100vh;
}

.wrap {
  background: #ffffff;
  padding: 32px;
  border-radius: 24px;
  position: relative;
  z-index: 999;
  width: 400px;
}


