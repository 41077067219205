.sendModal :global(.ant-modal-content) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--strokes-dividers-stroke-50, #F4F4F4);
}

.sendModal :global(.ant-modal-header) {
  border: none;
  margin-bottom: 12px;
}

.sendModal :global(.ant-modal-body) {
  width: 100%;
}

.sendModal :global(.ant-modal-title) {
  color: var(--Text-text-primary, #22262B);
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.sendModal :global(.ant-modal-close) {
  top: 25px;
}

.sendModal :global(.ant-modal-footer) {
  width: 100%;
  display: flex;
  margin-top: 0;
}

.modalText {
  color: var(--Text-text-secondary, #64676B);
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.modalButton {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--Button-bg-primary-default, #22262B);
  overflow: hidden;
  color: var(--Text-text-white, #FFF);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  height: 38px;
}

.modalButtonCancel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  flex: 1 0 0;
  border-radius: 8px;
  border: none;
  height: 38px;
  background: var(--button-bg-secondary-default-hover, #F4F4F4);
  overflow: hidden;
  color: var(--Text-text-secondary, #64676B);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
}

.descriptionText {
  margin: 0;
  overflow: hidden;
  color: var(--Text-text-secondary, #64676B);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
}

.descriptionWrapper {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 8px;
  align-items: center;
  padding-bottom: 24px;
}

.dropdownWrapper {
  padding-top: 4px;
  column-gap: 4px;
  display: flex;
  width: 100%;
}

.input {
  height: 38px;
  width: 100%;
}

.plusButton {
  display: flex;
  height: 38px;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--strokes-dividers-stroke-50, #F4F4F4);
  background: var(--Background-bg-surface, #FFF);
  &:disabled{
    cursor: not-allowed;
    border: 1px solid var(--strokes-dividers-stroke-50, #F4F4F4);
    background: var(--Background-bg-surface, #FFF);
  }
}


