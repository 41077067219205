.filters {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.selectContainer {
  :global(.ant-select-selector) {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }
}

:global(.ant-picker) {
  padding: 8px 12px;
  height: 40px;
}

:global(.ant-picker-range-separator) {
  color: #919395;
}

.rangePicker {
  :global(.ant-picker-input > input) {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px !important;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    font-style: normal;
    line-height: 16px;
    gap: 4px;
  }
}

.nestedFilters {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 8px;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid #E9E9EA;
  background-color: #FFFFFF;
  margin-bottom: 16px;
}

.defaultFilter {
  padding: 8px 0;
}

.switch {
  display: flex;
  align-items: center;

  &_title {
    margin-right: 5px;
  }
}
