.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table {
  margin-top: 16px;
}

.sentCustomer {
  cursor: pointer;
}

.status {
  display: inline-block;
  margin: 0 auto;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  border: 1px solid;
  font-size: 10px;
  line-height: 16px;
}
