.input {
  display: none;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.file {
  border-radius: 8px;
  background: var(--Background-bg-general, #F7F8F9);
  padding: 8px 16px;
  color: var(--Text-text-primary, #22262B);
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;

  display: flex;
  align-items: center;

  &Name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &Actions {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 12px;
    padding-left: 12px;
    border-left: 1px solid #D3D4D5;
  }
}
