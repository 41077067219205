.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  align-self: stretch;
  margin-bottom: 24px;
  h2 {
    color: var(--Text-text-primary, #22262B);
    margin: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }
}

.filtersTitle {
  h2 {
    color: var(--Text-text-primary, #22262B);
    margin: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }
}

.icon {
  cursor: pointer;
}

.card {
  border-radius: 16px;
  :global(.ant-card-body) {
    padding: 16px;
  }
  &_noMargin {
    border-radius: 16px;
    :global(.ant-card-body) {
      padding: 24px 24px 16px 24px;
    }
  }
  &_margin {
    border-radius: 16px;
    :global(.ant-card-body) {
      padding: 24px;
    }
  }
}

.placeholder {
  color: var(--Text-text-tertiary, #919395);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
}

.info {
  color: var(--Text-text-primary, #22262B);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  margin: 0;
  }

.paginations {
  margin: 24px 0 104px 0;
}

.circleButton {
  width: 80px !important;
  height: 80px;
  background: #22262B;
  display:flex;
  align-items: center;
  justify-content: center;
  &:active {
    background-color: #383C40 !important;
  }
  &:hover {
    background-color: #2D3136 !important;
  }
}

.cardTitle {
  color: var(--Text-text-primary, #22262B);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin: 16px 0;
  line-height: 28px; /* 155.556% */

}

.col {
  margin-bottom: 8px;
}

.divider {
  margin: 0 0 12px 0 !important;
}

.cardContent {
  flex: 1;
}

.cardFooter {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: auto;
}

