.signatureCanvasContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}

.signatureCanvas {
  border-radius: 8px;
  border: 1px solid var(--strokes-dividers-stroke-50, #F4F4F4);
  background: var(--Const-white, #FFF);
  padding: 8px 12px;
  &Error {
    border: 1px solid red;
  }
}

.signatureError {
  color: red;
  font-size: 12px;
}

.placeholder {
  margin-bottom: 4px;
  color: var(--Text-text-tertiary, #919395);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.clearButton {
  display: flex;
  margin-top: 12px;
  padding: 5px 10px;
  align-items: center;
  gap: 4px;
  border-radius: 5px;
  border: 1px solid var(--strokes-dividers-stroke-100, #E9E9EA);
  color: #22262B;
}
