.revenue {
  border-radius: 16px;
  border: 1px solid #E9E9EA;
  background: #FFF;
  padding: 16px;

  &Title {
    color: #919395;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  &Price {
    color: #22262B;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-top: 4px;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.tabs {
  border-radius: 16px;
  border: 1px solid #F4F4F4;
  background: #FFF;
  padding: 4px;

  button {
    padding: 8px 16px;
    border-radius: 12px;
    color: #64676B;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

    &.active {
      background: #F4F4F4;
      color: #22262B;
    }
  }
}

.table {
  margin-top: 16px;
}
